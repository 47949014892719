<template>
  <div id="app">
    <router-view />
    <particles-bg type="custom" :config="config" :bg="true" />
  </div>
</template>

<script>
import { ParticlesBg } from 'particles-bg-vue'
import icon from './assets/icon.js'

export default {
  components: {
    ParticlesBg,
  },
  name: 'App',
  data() {
    return {
      config: {
        num: [4, 7],
        rps: 0.1,
        radius: [5, 40],
        life: [1.5, 3],
        v: [0.1, 1],
        tha: [-30, 30],
        body: icon,
        alpha: [0.6, 0],
        scale: [0.1, 0.6],
        position: 'all',
        cross: 'dead',
        random: 15,
      },
      file: null,
      value: 0,
      success: null,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
